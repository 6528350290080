<template>
  <v-app>
    <main class="main-landing" id="home">
      <div class="overlay-bg"></div>
      <!--Start Navigation-->
      <nav class="navbar fixed-top">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img
              src="./img/logo-nav.png"
              alt="Logo Riverine"
              height="55"
              class="d-inline-block align-text-top"
            />
          </a>
          <div
            id="menu"
            :class="{ menuactive: isActive }"
            class="menu d-flex flex-column flex-md-row justify-content-center align-items-center"
          >
            <img
              class="d-block d-md-none position-absolute"
              src="./img/logo-nav.png"
              alt="Logo Riverine"
              height="60"
            />
            <ul
              class="d-flex mb-0 flex-column flex-md-row align-items-center p-0"
            >
              <a @click="isActive = !isActive" class="text-white" href="#home">
                <li class="px-md-2">Home</li>
              </a>
              <a
                @click="isActive = !isActive"
                class="text-white"
                href="#services"
              >
                <li class="px-md-2">Servicios</li>
              </a>
              <a
                @click="isActive = !isActive"
                class="text-white"
                href="#projects"
              >
                <li class="px-md-2">Proyectos</li>
              </a>
              <a
                @click="isActive = !isActive"
                class="text-white"
                href="#about-us"
              >
                <li class="px-md-2">Nosotros</li>
              </a>
              <a
                @click="isActive = !isActive"
                class="text-white"
                href="#contact"
              >
                <li class="px-md-2">Contacto</li>
              </a>
              <router-link class="text-center" to="/login" v-if="!loggedIn">
                <button class="login-btn mb-3 mb-md-0">INICIAR SESIÓN</button>
              </router-link>
              <v-btn
                class="login-btn mb-3 mb-md-0"
                @click="logout"
                v-if="loggedIn"
              >
                CERRAR SESIÓN
              </v-btn>
            </ul>
          </div>
          <div class="menu-toggle">
            <a
              id="menuToggle"
              @click="isActive = !isActive"
              class="text-white"
              href="#main-nav"
            >
              <div :class="{ navactive: isActive }" class="bar">
                <i>Menu</i>
              </div>
            </a>
          </div>
        </div>
      </nav>
      <!--End Navigation-->
      <div
        class="container col-md-8 offset-md-2 d-flex justify-content-center align-items-center flex-column"
      >
        <h1
          class="text-white text-center animate__animated animate__fadeInDown"
        >
          {{ othersContent.main_title }}
        </h1>
        <a class="over" href="#services"
          ><button class="mt-3 px-4 py-3">¿Qué hacemos?</button></a
        >
      </div>
    </main>

    <!-- Services -->
    <div id="services" class="bg-light-gray">
      <div class="container py-5">
        <h2 class="text-center text-primary-blue position-relative title-home">
          Servicios
        </h2>
        <div class="col-lg-10 offset-lg-1 row mx-auto mt-5">
          <!-- Cards services -->
          <div class="col-md-4" v-for="service in services">
            <div class="card bg-white position-relative">
              <div
                class="card-icon position-absolute d-flex justify-content-center"
              >
                <img
                  class="img-fluid"
                  :src="getApiUrl(service.icon.meta.download_url)"
                  :alt="service.title"
                />
              </div>
              <h4 class="text-center text-primary-blue px-4">
                {{ service.title }}
              </h4>
              <div class="d-flex flex-column px-4 pt-3">
                <p class="text-card">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Servicios cierre -->

    <!-- Projects v2 -->
    <!-- https://vuetifyjs.com/en/components/slide-groups/#custom-icons -->
    <div id="projects" class="bg-light-gray">
      <div class="container py-6" max-width="1230px">
        <h2
          class="text-center text-primary-blue position-relative title-home mb-5"
        >
          Proyectos
        </h2>
        <div
          class="col-lg-12 offset-lg-0 row mx-auto px-0 mt-5 py-2 justify-content-center"
        >
          <v-sheet class="mx-auto" elevation="8">
            <v-slide-group
              class="pa-4"
              selected-class="bg-primary"
              v-model="slideGroup"
              v-on:click:next="stopAutoSlide()"
              v-on:click:prev="stopAutoSlide()"
              show-arrows
            >
              <v-slide-item v-for="(project, i) in projects" :key="i">
                <v-card
                  class="ma-4"
                  height="28em"
                  width="28em"
                  @click="stopAutoSlide()"
                >
                  <v-img
                    :src="getApiUrl(project.image.meta.download_url)"
                    class="align-center"
                    height="100%"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  >
                    <v-card-title
                      class="text-white justify-center text-center text-h3"
                      v-text="project.title"
                    ></v-card-title>
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
    </div>
    <!-- Plataforma -->
    <div class="bg-primary-blue bg-plataforma">
      <div class="container py-5">
        <h2 class="text-center text-white position-relative title-white mb-5">
          Plataforma
        </h2>
        <div class="col-lg-10 offset-lg-1">
          <div class="row mt-5">
            <div
              class="col-md-5 d-flex flex-column align-items-center justify-content-center"
            >
              <h2 class="text-center my-5 text-white">
                {{ othersContent.platform_subtitle }}
              </h2>
              <p class="text-white">
                {{ othersContent.platform_description }}
              </p>
              <router-link class="text-center" to="/login">
                <v-btn color="white" rounded>
                  <span class="text-primary-blue"> Ir a Plataforma </span>
                </v-btn>
              </router-link>
            </div>
            <div class="col-md-7">
              <img class="img-fluid" src="./img/mocap.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Nuestros clientes -->
    <div class="bg-light-gray bg-gray">
      <div class="container py-5">
        <div class="col-lg-10 offset-lg-1">
          <div class="row">
            <div
              class="col-sm-6 d-flex justify-content-center align-items-center mb-5"
            >
              <h2
                class="text-center text-primary-blue position-relative title-home mb-0 mt-0"
              >
                Nuestros clientes
              </h2>
            </div>
            <div class="col-sm-6">
              <div class="row align-items-center">
                <div
                  class="col-4 d-flex justify-content-center p-2 p-sm-5"
                  v-for="client in clients"
                >
                  <a :href="client.weburl">
                    <img
                      class="img-fluid w-100 filter-opacity"
                      :src="getApiUrl(client.logo.meta.download_url)"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Quienes somos -->
    <div class="bg-light-gray" id="about-us">
      <div class="container py-5">
        <h2 class="text-center text-primary-blue position-relative title-home">
          Nosotros
        </h2>
        <div
          class="col-lg-10 offset-lg-1 row mt-5 mx-auto d-flex justify-content-center"
        >
          <p class="px-4">
            {{ othersContent.company_description }}
          </p>

          <div class="row mt-3">
            <div
              class="col-md-4 d-flex flex-column align-items-center p-4"
              v-for="com in company"
            >
              <img
                class="img-fluid p-2"
                width="50%"
                :src="getApiUrl(com.icon.meta.download_url)"
                :alt="com.card_name"
              />
              <h4 class="text-center text-primary-blue mt-4">
                {{ com.card_name }}
              </h4>
              <p class="text-center mt-4 text-justify">
                {{ com.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nuestro Equipo -->
    <div class="bg-secondary-blue" id="our-team">
      <div class="py-5">
        <h2
          class="text-center text-primary-blue position-relative mt-5 title-white text-white"
        >
          Nuestro equipo
        </h2>
        <div class="col-lg-10 offset-lg-1 row mx-auto mt-5 row-persona">
          <!-- Persona1 -->
          <!-- <div class="col p-4" v-for="mem in team"> -->
          <div class="col-lg-4 col-md-3 col-sm-6 col-12" v-for="mem in team">
            <a
              href=""
              data-bs-toggle="modal"
              :data-bs-target="'#' + mem.modaltag"
              class="position-relative"
            >
              <div class="card p-5 p-md-3 p-xl-5 profile">
                <div class="profile-photo">
                  <img
                    class="img-fluid w-100"
                    :src="getApiUrl(mem.profile_picture.meta.download_url)"
                    :alt="mem.member_name"
                  />
                </div>
                <h4 class="text-center pt-4 pb-2">{{ mem.member_name }}</h4>
                <p class="text-center" v-html="mem.description"></p>
                <div
                  id="my-div"
                  class="position-relative"
                  style="margin-top: auto"
                >
                  <v-btn block color="white" rounded>
                    <span class="text-primary-blue"> Ver más </span>
                  </v-btn>
                </div>
              </div>
            </a>
            <div
              class="modal fade"
              :id="mem.modaltag"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-secondary-blue">
                      {{ mem.member_name }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body px-4">
                    <div class="modal-photo mx-auto">
                      <img
                        class="img-fluid"
                        :src="getApiUrl(mem.profile_picture.meta.download_url)"
                        :alt="mem.member_name"
                      />
                    </div>
                    <p v-html="mem.resume"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Persona1 cierre -->
        </div>
      </div>
    </div>

    <!-- BLOG -->
    <!-- <div class="bg-light-gray">
      <div class="container py-5">
        <h2 class=" text-center text-primary-blue position-relative title-home">Blog </h2>
        <div class="row mt-5">
          <div class="col-sm-6">
            <div class="card blog-card overflow-hidden mb-3">
              <div class="row g-0">
                <div class="col-lg-4 blog-card_img" style="background-image: url('./img/proyecto1.jpg')"></div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h5 class="card-title text-primary-blue mb-3">Nombre entrada de blog</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Subtitulo blog</h6>
                    <p class="card-text"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, iure! </p>
                    <button class="btn bg-primary text-white">Leer más</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card blog-card overflow-hidden mb-3">
              <div class="row g-0">
                <div class="col-lg-4 blog-card_img" style="background-image: url('./img/proyecto1.jpg')"></div>
                <div class="col-lg-8">
                  <div class="card-body">
                    <h5 class="card-title text-primary-blue mb-3">Nombre entrada de blog</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Subtitulo blog</h6>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, iure!</p>
                    <button class="btn bg-primary text-white">Leer más</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Contacto -->
    <contact-form />

    <!-- Footer -->
    <footer class="bg-secondary-blue py-3">
      <div class="container">
        <div class="row flex-column-reverse flex-md-row">
          <div class="col-md-6 text-center text-md-start">
            <h6 class="text-white mb-3 mt-4"><b>Datos de contacto</b></h6>
            <ul class="p-0">
              <li>
                <i class="mdi mdi-phone text-white"></i>
                <a class="text-white-link" href="tel:+56945423887"
                  >+56 9 4542 3887</a
                >
              </li>
              <li>
                <i class="mdi mdi-phone text-white"></i>
                <a class="text-white-link" href="tel:+56988158237"
                  >+56 9 8815 8237</a
                >
              </li>
              <li>
                <i class="mdi mdi-email-outline text-white"></i>
                <a class="text-white-link" href="mailto:contacto@riverine.cl"
                  >contacto@riverine.cl</a
                >
              </li>
              <li>
                <i class="mdi mdi-map-marker text-white"></i>
                <a
                  class="text-white-link"
                  href="https://riverine.cl/contacto/#:~:text=9%208815%208237-,Hu%C3%A9rfanos%201055%2C%20OF%20503%2C%20Santiago,-%C2%A9%202020%20Riverine"
                  >Huérfanos 1055, OF 503, Santiago</a
                >
              </li>
            </ul>
          </div>
          <div
            class="col-md-6 d-flex justify-content-center justify-content-lg-end align-items-center"
          >
            <a href="#"
              ><img height="100" src="./img/logo-white.png" alt="Logo Riverine"
            /></a>
          </div>
        </div>
        <hr />
        <p class="text-white text-center text-cr">
          © Riverine 2023 - Todos los derechos reservados.
        </p>
      </div>
    </footer>
  </v-app>
</template>

<script>
import ApiService from "@/common/api.service";
import store from "@/store";

export default {
  name: "HomeIndex",
  components: {
    ContactForm: () => import("@/views/home/components/ContactForm.vue"),
  },
  methods: {
    getSrc(image_path) {
      return require("./img/" + image_path);
    },
    createSlideInterval() {
      if (!this.slideIntervalCreated) {
        this.slideIntervalCreated = true;
        this.interval = setInterval(() => {
          this.slideGroup = this.slideGroup + 1;
          // set this threshold to the length of projects
          if (this.slideGroup >= this.projects.length) {
            this.slideGroup = 0;
          }
        }, 3000);
      }
    },
    stopAutoSlide(event) {
      clearInterval(this.interval);
      this.slideIntervalCreated = false;
    },
    handleScroll(event) {
      if ($(window).scrollTop()) {
        $("nav").addClass("bg-nav");
        $(".login-btn").addClass("login-scroll-btn");
      } else {
        $("nav").removeClass("bg-nav");
        $(".login-btn").removeClass("login-scroll-btn");
      }
      // on scroll, restore the interval
      this.createSlideInterval();
    },
    callTeamApi() {
      // make call
      ApiService.getPublicApi(
        "api/v2/team-pages/?is_active=true&fields=member_name,description,resume,profile_picture"
      ).then((response) => {
        this.team = response.data.items;
        for (let i = 0; i < this.team.length; i++) {
          this.team[i].modaltag = "persona" + String(i + 1);
        }
      });
    },
    callClientsApi() {
      // make call
      ApiService.getPublicApi(
        "api/v2/client-pages/?is_active=true&fields=client_name,logo,weburl"
      ).then((response) => {
        this.clients = response.data.items;
      });
    },
    callServicesApi() {
      // make call
      ApiService.getPublicApi(
        "api/v2/service-pages/?is_active=true&fields=service_name,description,icon"
      ).then((response) => {
        this.services = response.data.items;
      });
    },
    callProjectsApi() {
      // make call
      ApiService.getPublicApi(
        "api/v2/project-pages/?is_active=true&fields=description,image"
      ).then((response) => {
        this.projects = response.data.items;
      });
    },
    callCompanyApi() {
      ApiService.getPublicApi(
        "api/v2/company-pages/?fields=card_name,description,icon"
      ).then((response) => {
        this.company = response.data.items;
      });
    },
    callOthersApi() {
      ApiService.getPublicApi(
        "api/v2/other-pages/?fields=main_title,platform_subtitle,platform_description,company_description"
      ).then((response) => {
        console.log(response);
        this.othersContent = response.data.items[0];
      });
    },
    getApiUrl(url) {
      return ApiService.API_URL + `${url}`;
    },
    logout() {
      store.dispatch("logout");
    },
  },
  data: () => ({
    expandOnHover: false,
    isActive: false,
    model: null,
    slideGroup: 0, // model for the projects slider
    slideIntervalCreated: false,
    projects: [],
    team: [],
    services: [],
    clients: [],
    company: [],
    othersContent: {
      main_title: "",
      platform_subtitle: "",
      platform_description: "",
      company_description: "",
    },
    loggedIn: false,
  }),
  created() {
    // the scroll event
    window.addEventListener("scroll", this.handleScroll);
    // call the servicess data
    this.callProjectsApi();
    // call the servicess data
    this.callServicesApi();
    // create the slide interval
    this.createSlideInterval();
    // call the company data
    this.callCompanyApi();
    // call the team data
    this.callTeamApi();
    // call the clients data
    this.callClientsApi();
    // call the clients data
    this.callOthersApi();
    // check logged in
    this.loggedIn = store.state.isAuthenticated;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
@import "../../assets/home/css/main.css";
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
